import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import {  SectionHeader } from '../components/molecules';


export const CallToAction = ({ title, subtitle, className, action, href, ...props }) => {
  const button = (
    <Button
      variant="contained"
      color="primary"
      component="a"
      href={href}
    >
      {action}
    </Button>
  );
  return (
    <div className={className}>
      <SectionHeader
        title={title}
        subtitle={subtitle}
        align="center"
        titleProps={{
          variant: 'h3',
          color: 'textPrimary',
        }}
        ctaGroup={[button]}
      />
    </div>
  );
};

CallToAction.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};
