
import {images} from "../components/MediaLib";


export const BuiltOn = [
  {
    name: 'Apache Cloudstack',
    logo: images.cloudstack_logo_m,
  },
  {
    name: 'Ansible',
    logo: images.ansible_logo_m,
  },
  {
    name: 'Docker',
    logo: images.docker_logo_m,
  }

];