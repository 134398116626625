import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { Image } from '../components/atoms';
import { SectionHeader } from '../components/molecules';
import { Section } from '../components/organisms';
import {images} from '../components/MediaLib'

const useStyles = makeStyles(theme => ({
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

export const Result = ({message, back_href, back_message, ...props}) => {
  const classes = useStyles();

  return (
    <div>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} border="0" >
            <Image src={images.done} />
          </Box>
          <SectionHeader          
            subtitle={message}
            titleProps={{
              variant: 'h3',
            }}
            ctaGroup={[
              <Button
                size="large"
                variant="contained"
                color="primary"
                href={back_href}
              >
                {back_message}
              </Button>,
            ]}
            disableGutter
          />
        </div>
      </Section>
    </div>
  );
};

Result.propTypes = {
  message: PropTypes.string.isRequired,
  back_href: PropTypes.string.isRequired,
  back_message: PropTypes.string.isRequired,

}