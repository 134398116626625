
export const CompanyData={
    short_name:"Privazio",
    name: "Privazio",
    address: "",
    short_address: "",
    email: "hello@privaz.io",
    support_email: "support@privaz.io",
    phone: "",
    org_number: "",
    VAT: "",
    support_portal: "https://support.privaz.io",
    linkedin_url: "https://get.privaz.io/asset/6:privazio-linkedin",
    gitlab_url: "https://get.privaz.io/asset/1:privazio-gitlab",
    telegram_url: "https://get.privaz.io/asset/3:privazio-telegram-support",
    twitter_url: "https://get.privaz.io/asset/2:privazio-twitter",

    mautic_subscribe_action_url: "https://get.privaz.io/form/submit?formId=1"
};
