/**
 * This is a forked component form WithLayout
 * It is been kept separate form WithLayout to facilitate merging new upstream code form the provider
 */

import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import getTheme from 'theme';
import { Divider } from "@material-ui/core";

import AOS from 'aos';

export const useDarkMode = () => {
    const [themeMode, setTheme] = useState('light');
    const [mountedComponent, setMountedComponent] = useState(false);

    const setMode = mode => {
        window.localStorage.setItem('themeMode', mode);
        setTheme(mode);
    };

    const themeToggler = () => {
        themeMode === 'light' ? setMode('dark') : setMode('light');
    };

    useEffect(() => {
        const localTheme = window.localStorage.getItem('themeMode');
        localTheme ? setTheme(localTheme) : setMode('light');
        setMountedComponent(true);
        AOS.refresh();
    }, []);

    useEffect(() => {
        AOS.refresh();
    }, [themeMode]);

    return [themeMode, themeToggler, mountedComponent];
};

export default function WithLayout({ layout: Layout, ...rest }) {
    React.useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        AOS.init({
            once: true,
            delay: 50,
            duration: 500,
            easing: 'ease-in-out',
        });
    }, []);

    const [themeMode, themeToggler, mountedComponent] = useDarkMode();
    useEffect(() => {
        AOS.refresh();
    }, [mountedComponent]);

    return (
        <ThemeProvider theme={getTheme(themeMode)}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Paper elevation={0}>
                <Layout themeMode={themeMode} themeToggler={themeToggler}>
                    {
                        React.Children.map(rest.children, (child) =>
                            // in the original design "rest" is also passed down... but
                            // i don't think it is a good pattern
                            // Also themeMode should not be set in components that are not expecting it, or will go to the
                            // DOM due to the ...rest pattern everywhere, which will trigger errors.
                            React.cloneElement(child, {themeMode:themeMode, ...child.props })
                        )
                    }
                </Layout>
            </Paper>
        </ThemeProvider>
    );
}