import React from 'react';
import PropTypes from 'prop-types';
import { SectionHeader } from '../components/molecules';
import { Section } from '../components/organisms';
import { MauticForm } from '../components/organisms';


export const MauticSubscribe = ({title, subtitle, subscribe_action_url, ...props}) => {
  return (
      <Section>
        <SectionHeader
          title={title}
          subtitle={subtitle}
          data-aos="fade-up"
          align="center"
          titleProps={{
            variant: 'h3',
            color: 'textPrimary',
          }}
        />
        <MauticForm submit_url={subscribe_action_url}/>
      </Section>
  );
};

MauticSubscribe.propTypes={
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subscribe_action_url: PropTypes.string.isRequired
};