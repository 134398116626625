
//const {t} = useTranslation();
const t = (txt)=>txt;

export const HowItWorks = [
  {
    icon: 'fas fa-layer-group',
    title: t('Layered Automation'),
    description:
      t('Multiple Layered Ansible Collections are delivered, they target infrastructure automation at different independent levels with clear interfaces between them.'),
  },
  {
    icon: 'fas fa-toolbox',
    title: t('Inmutable Toolkit'),
    description:
      t('Ansible, Collections, Plugins and Python Dependencies are all sealed in an inmutable dockerized toolkit.'),
  },
  {
    icon: 'fas fa-cogs',
    title: t('Continuous delivery'),
    description:
      t('The toolkit is continiously tested against multiple versions of all dependencies: OS, ACS, Ansible, Collections, etc. A new ACS deployment is created and tested every night and on every project commit.'),
  },
  {
    icon: 'fas fa-terminal',
    title: t('Privazio CLI'),
    description:
      t('The Privazio toolkit is delivered as a simple Command Line Interface. Your environment is ready to automate your cloud deployement with maximum confidence, from metal to service.'),
  },
];