import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { SectionHeader, TypedText } from 'components/molecules';
import { HeroShaped } from 'components/organisms';


export const MosaicHero = ({ action, href, title_lead, title_typed_ends, banner_image, banner_image_dk, themeMode = 'light', className, ...props }) => {

  const useStyles = makeStyles(theme => ({
    fontWeight900: {
      fontWeight: 900,
    },
    leftSideContent: {
      '& .section-header__cta-container': {
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          '& .section-header__cta-item-wrapper': {
            width: '100%',
            '&:last-child': {
              marginLeft: 0,
              marginTop: theme.spacing(1),
            },
            '& .MuiButtonBase-root': {
              width: '100%',
            },
          },
        },
      }
    },
    heroShaped: {
      '& .hero-shaped__image': {
        backgroundColor: theme.palette.alternate.main,
      },
      [theme.breakpoints.down('sm')]: {
        '& .hero-shaped__image': {
          position: 'relative',
        },
        '& .hero-shaped__wrapper': {
          flexDirection: 'column',
        },
      },
    },
    imageAnimation: {
      background: `url("${banner_image}")`,
      backgroundRepeat: 'repeat',
      backgroundAttachment: 'scroll',
      backgroundSize: '400px auto',
      animation: `$slideshow 50s linear infinite`,
      width: '600%',
      height: '600%',
      backgroundColor: theme.palette.alternate.dark,
      top: '-25%',
      left: '-100%',
      position: 'absolute',
      [theme.breakpoints.up('sm')]: {
        backgroundSize: '800px auto',
      }
    },
    imageAnimationDark: {
      background: `url("${banner_image_dk}")`,
    },
    '@keyframes slideshow': {
      '0%': {
        transform: 'rotate(-13deg) translateY(-25%)',
      },
      '100%': {
        transform: 'rotate(-13deg) translateY(-80%)',
      },
    },
  }));

  const classes = useStyles();

  const title = (
    <Typography variant="h3" component="span" className={classes.fontWeight900}>
      {title_lead}
      <br />
      <TypedText
        component="span"
        variant="h2"
        color="secondary"
        className={classes.fontWeight900}
        typedProps={{
          strings: title_typed_ends,
          typeSpeed: 50,
          loop: true,
        }}
      />
    </Typography>
  );

  const subtitle = props.subtitle;

  const startButton = (
    <Button
      size="large"
      variant="contained"
      color="primary"
      component="a"
      href={href}
    >
      {action}
    </Button>
  );

  const leftSideContent = (
    <SectionHeader
      title={title}
      subtitle={subtitle}
      align="left"
      titleProps={{
        variant: 'h2',
        color: 'textPrimary',
      }}
      ctaGroup={[startButton]}
      data-aos="fade-right"
      disableGutter
      className={classes.leftSideContent}
    />
  );

  return (
    <div className={className}>
      <HeroShaped
        className={classes.heroShaped}
        leftSide={leftSideContent}
        rightSide={(
          <div
            className={clsx(
              classes.imageAnimation,
              themeMode === 'dark' ? classes.imageAnimationDark: '',
            )}
          />
        )}
      />
    </div>
  );
};

MosaicHero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Theme mode
   */
  themeMode: PropTypes.string,
  action: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  title_lead: PropTypes.string.isRequired,
  title_typed_ends: PropTypes.array.isRequired,
  banner_image: PropTypes.string.isRequired,
  banner_image_dk: PropTypes.string.isRequired
};
