
//const {t} = useTranslation();
const t = (txt)=>txt;

export const MetalChoice = [
  {
    icon: 'fas fa-server',
    title: 'Dedicated Servers',
    subtitle:
      'Privazio is designed to deploy to dedicated servers, including affordable options such as Hetzner or Scaleway.',
  },
  {
    icon: 'fa fa-hdd-o',
    title: 'Own metal',
    subtitle:
      'Privazio can deploy to low power industrial servers easy to host at your office, home or garage.',
  },
  {
    icon: 'fa fa-connectdevelop',
    title: 'Hybrid deployment',
    subtitle:
      "Privazio is designed to allow you mixing both dedicated and own metal.",
  },
];
