
const images={
    light_logo: require('assets/images/logo/logohc.svg'),
    dark_logo: require('assets/images/logo/logohi.svg'),
    hero_mosaic_light: require('assets/images/pzo/home-hero-bg-light.png'),
    hero_mosaic_dark: require('assets/images/pzo/home-hero-bg-dark.png'),

    // Stock photos
    coding: require('assets/images/3pty/coding.jpeg'),
    cables: require("assets/images/3pty/cables.jpeg"),

    // logos
    cloudstack_logo_m: require("assets/images/3pty/Cloudstack_mono.svg"),
    ansible_logo_m: require("assets/images/3pty/Ansible_mono.svg"),
    docker_logo_m: require("assets/images/3pty/Docker_mono.svg"),

    // Icons
    done: require("assets/images/done.svg")

};


const icons={

};

export {images,icons};